import Image from 'next/image';
import PropTypes from 'prop-types';
import Link from 'next/link';

const TestimonialBox = ({
  productImage,
  headshot,
  firstName,
  fullName,
  handle,
  testimonial,
  productLink,
  productName,
  carouselLink,
}) => {
  if (!testimonial || !carouselLink) return <></>;
  return (
    <>
      <div className="testimonial-box-container">
        <div className="testimonial-box">
          <div className="testimonial-image">
            {productImage && (
              <Image draggable={false}
                src={
                  productImage?.url || '/images/placeholders/testimonial-1.png'
                }
                alt={productImage?.alt || 'Testimonial'}
                layout="fill"
                objectFit="cover"
              />
            )}
          </div>

          {carouselLink ? (
            <Link href={carouselLink || '#'} passHref>
              <a
                className="testimonial"
                target="black"
                href={carouselLink || '#'}
              >
                <div className="quote">“</div>
                <div className="text">{testimonial}</div>
                <div className="profile">
                  <div>
                    {headshot && (
                      <Image draggable={false}
                        src={
                          headshot?.url ||
                          '/images/placeholders/testimonial-headshot.png'
                        }
                        alt={headshot.alt || 'Headshot'}
                        height="72"
                        width="72"
                      />
                    )}
                  </div>
                  <div className="info">
                    <div className="name">{fullName}</div>
                    <div className="handle">{handle}</div>
                    {firstName && productLink && productName && (
                      <div className="model">
                        {firstName} uses the&nbsp;
                        <a className="link" href={productLink || '#'}>
                          {productName}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </a>
            </Link>
          ) : (
            <div className="testimonial">
              <div className="quote">“</div>
              <div className="text">{testimonial}</div>
              <div className="profile">
                <div>
                  {headshot && (
                    <Image draggable={false}
                      src={
                        headshot?.url ||
                        '/images/placeholders/testimonial-headshot.png'
                      }
                      alt={headshot.alt || 'Headshot'}
                      height="72"
                      width="72"
                    />
                  )}
                </div>
                <div className="info">
                  <div className="name">{fullName}</div>
                  <div className="handle">{handle}</div>
                  {firstName && productLink && productName && (
                    <div className="model">
                      {firstName} uses the&nbsp;
                      <a className="link" href={productLink || '#'}>
                        {productName}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <style jsx>
        {`
          .testimonial-box {
            max-width: 885px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            background-color: #141414;
          }
          .testimonial-image {
            position: relative;
            height: 419px;
            min-width: 419px;
          }
          .testimonial {
            height: 419px;
            min-width: 466px;
            padding: 16px 32px 24px 40px;
            position: relative;
          }
          .quote {
            font-family: 'big_noodle_titling';
            font-size: 80px;
            color: #fd0300;
          }
          .text {
            margin-top: -16px;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            line-height: 24px;
            color: #e4e4e4;
          }
          .profile {
            position: absolute;
            bottom: 24px;
            display: flex;
            gap: 16px;
          }
          .info {
            margin-top: 4px;
            font-family: 'Roboto-Medium';
            font-size: 13px;
            line-height: 20px;
          }
          .name {
            color: #949494;
          }
          .handle {
            color: #e4e4e4;
          }
          .model {
            margin-top: 8px;
            font-size: 10px;
            line-height: 16px;
            color: #949494;
          }
          .link {
            color: #fd0300;
          }
          @media (max-width: 425px) {
            .testimonial-box-container {
              padding: 0 8px;
            }
          }
          @media (max-width: 768px) {
            .testimonial-box-container {
              padding: 0 16px;
            }
            .testimonial-box {
              flex-direction: column;
              width: 100%;
            }
            .testimonial-image {
              min-width: unset;
              height: 256px;
            }
            .testimonial {
              padding-right: 16px;
              padding-left: 16px;
              height: unset;
              min-width: unset;
            }
            .profile {
              position: unset;
              margin-top: 24px;
            }
          }
          @media (min-width: 769px) and (max-width: 1024px) {
            .testimonial {
              min-width: 350px;
              height: 480px;
            }
            .testimonial-image {
              min-width: 300px;
              height: 480px;
            }
          }
        `}
      </style>
    </>
  );
};

TestimonialBox.propTypes = {
  firstName: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  handle: PropTypes.string,
  testimonial: PropTypes.string.isRequired,
  productLink: PropTypes.string,
  productName: PropTypes.string,
  productImage: PropTypes.shape({
    alt: PropTypes.string,
    copyright: PropTypes.string,
    dimensions: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    url: PropTypes.string,
  }),
  carouselLink: PropTypes.string,
  headshot: PropTypes.shape({
    alt: PropTypes.string,
    copyright: PropTypes.string,
    dimensions: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    url: PropTypes.string,
  }),
};

TestimonialBox.defaultProps = {
  handle: null,
  productLink: null,
  productName: null,
  productImage: null,
  headshot: null,
  carouselLink: null,
};

export default TestimonialBox;
