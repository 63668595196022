import RangeSelected from 'components/RangeSelected';

const CarouselBlock = ({ items, selectedRange }) => {
  const gamingDesktops = items.filter(
    (ele) => ele.range_handle === 'gaming/desktops',
  );

  const gamingLaptops = items.filter(
    (ele) => ele.range_handle === 'gaming/laptops',
  );
  const workstationsDesktops = items.filter(
    (ele) => ele.range_handle === 'workstations/desktops',
  );
  const workstationsLaptops = items.filter(
    (ele) => ele.range_handle === 'workstations/laptops',
  );

  const renderRangeData = (selectedRange) => {
    switch (selectedRange) {
      case 'gaming/desktops':
        return (
          <div>
            <RangeSelected items={items} rangeData={gamingDesktops} />
          </div>
        );
      case 'gaming/laptops':
        return (
          <div>
            <RangeSelected items={items} rangeData={gamingLaptops} />
          </div>
        );
      case 'workstations/desktops':
        return (
          <div>
            <RangeSelected items={items} rangeData={workstationsDesktops} />
          </div>
        );
      case 'workstations/laptops':
        return (
          <div>
            <RangeSelected items={items} rangeData={workstationsLaptops} />
          </div>
        );

      default:
        return '';
    }
  };
  return <div>{renderRangeData(selectedRange)}</div>;
};

export default CarouselBlock;
