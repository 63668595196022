import breakPoints from 'helpers/Styles';
import { linkResolver } from 'pages/api/preview';
import { RichText } from 'prismic-reactjs';

const HTMLTextsOnly = ({ block }) => {
  if (!block) return '';

  const { primary } = block;
  const backgroundColour = primary?.background_colour
    ? primary.background_colour
    : '#000000';
  const title = primary?.title1 ? primary.title1 : null;
  const titleColour = primary?.title1_colour
    ? primary.title1_colour
    : '#e4e4e4';
  const description = primary.html_content ? primary.html_content : null;
  const descriptionColour = primary?.html_content_colour
    ? primary.html_content_colour
    : '#e4e4e4';
  const fullWidth = primary?.full_width ? primary?.full_width : false;

  const descriptionMaxWidth = primary?.full_width ? '100%' : '50%';

  return (
    <>
      <div className="html_only_slice wrapper">
        <div className="slice_container">
          {title && <h1>{title}</h1>}

          {description && (
            <div className="description">
              {RichText.render(description, linkResolver)}
            </div>
          )}
        </div>
      </div>
      <style jsx>
        {`
          .html_only_slice {
            display: flex;
            justify-content: center;
            background: ${backgroundColour};
            width: 100%;
            overflow: hidden;
          }
          .slice_container {
            width: 100%;
            overflow: hidden;
          }
          .slice_container h1 {
            color: ${titleColour};
            font-family: 'big_noodle_titling';
            font-size: 39px;
            letter-spacing: 0;
            line-height: 48px;
            text-align: center;
          }
          .slice_container .description {
            max-width: ${descriptionMaxWidth};
            color: ${descriptionColour};
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            text-align: justify;
            margin: 0 auto;
          }

          @media (max-width: ${breakPoints.medium}) {
            .slice_container h1 {
              font-size: 26px;
              margin-top: 0;
            }

            .slice_container .description {
              max-width: 100%;
            }
          }
          @media (max-width: ${breakPoints.small}) {
            .html_only_slice {
              padding-top: 20px;
              padding-bottom: 20px;
            }
          }
        `}
      </style>
      <style global jsx>
        {`
          .html_only_slice a {
            text-decoration: underline !important;
          }

          .html_only_slice ul li,
          .html_only_slice ol li {
            text-align: left;
          }

          .slice_container .description img {
            max-width: 100%;
          }
        `}
      </style>
    </>
  );
};

export default HTMLTextsOnly;
