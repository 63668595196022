import breakPoints from 'helpers/Styles';
import Image from 'next/image';
import Link from 'next/link';
import InstagramBlockMobile from '../InstagramBlockMobile';

const InstagramBlock = ({ block }) => {
  if (!block) return '';
  const { primary } = block;
  const title = primary.block_title ? primary.block_title : null;
  const name = primary.name ? primary.name : null;
  const { items } = block;

  return (
    <>
      <div className="instagram_block wrapper">
        <div className="title_container">
          <p className="title">{title}</p>
          <h1 className="name">{name}</h1>
        </div>
        <div className="instagram_images">
          {items &&
            items.map((item, index) => (
              <div className="image_container" key={index}>
                <Link href={item.instagram_link.url || '#'}>
                  <a href={item.instagram_link.url || '#'}>
                    {item.image.url && (
                      <Image draggable={false}
                        src={item.image.url}
                        alt="instagram_icon"
                        width={200}
                        height={200}
                      />
                    )}
                  </a>
                </Link>
              </div>
            ))}
        </div>
      </div>
      <div className="instagram_mobile">
        <InstagramBlockMobile items={items} title={title} name={name} />
      </div>
      <style jsx>
        {`
          .instagram_block {
            min-height: 450px;
            width: 100%;
            margin-top: 100px;
          }
          .title {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            text-align: center;
          }
          .name {
            color: #e4e4e4;
            font-family: 'big_noodle_titling';
            font-size: 39px;
            letter-spacing: 0;
            line-height: 48px;
            text-align: center;
          }
          .instagram_images {
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
          }
          .image_container {
            cursor: pointer;
          }
          .instagram_mobile {
            display: none;
          }
          @media (max-width: ${breakPoints.medium}) {
            .instagram_block {
              display: none;
            }
            .instagram_mobile {
              display: block;
            }
          }
        `}
      </style>
    </>
  );
};

export default InstagramBlock;
