import TestimonialBox from 'components/TestimonialBox';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

const TestimonialSlides = ({ block }) => {
  const { items } = block;
  const sliderSettings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '250px',
    slidesToShow: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          centerPadding: '250px',
        },
      },
      {
        breakpoint: 1550,
        settings: {
          centerPadding: '180px',
        },
      },
      {
        breakpoint: 1450,
        settings: {
          centerPadding: '160px',
        },
      },
      {
        breakpoint: 1400,
        settings: {
          centerPadding: '160px',
        },
      },
      {
        breakpoint: 1300,
        settings: {
          centerPadding: '130px',
        },
      },
      {
        breakpoint: 1250,
        settings: {
          centerPadding: '80px',
        },
      },
      {
        breakpoint: 1150,
        settings: {
          centerPadding: '40px',
        },
      },
      {
        breakpoint: 1080,
        settings: {
          className: 'center',
          centerMode: true,
          infinite: true,
          centerPadding: '30px',
          slidesToShow: 1,
          speed: 500,
        },
      },
      {
        breakpoint: 768,
        settings: {
          className: 'center',
          centerMode: true,
          infinite: true,
          centerPadding: '40px',
          slidesToShow: 1,
          speed: 500,
        },
      },
    ],
  };
  return (
    <div className="testimonial-slides">
      <Slider {...sliderSettings}>
        {items.map((item) => (
          <TestimonialBox
            key={item.full_name}
            firstName={item.first_name}
            fullName={item.full_name}
            headshot={item.headshot}
            handle={item.handle}
            productImage={item.product_image}
            productName={item.product_name}
            productLink={item.product_link}
            testimonial={item.testimonial?.[0]?.text}
            carouselLink={item.carousel_link?.url}
          />
        ))}
      </Slider>
      <style jsx>
        {`
          .testimonial-slides {
            // max-width: 1440px;
            // width: 90%;
            margin: 0 auto;
            margin-bottom: 104px;
          }
          @media (min-width: 1700px) {
            .testimonial-slides {
              max-width: 1440px;
            }
          }
          @media (max-width: 1700px) {
            .testimonial-slides {
              width: 90%;
            }
          }
        `}
      </style>
    </div>
  );
};

TestimonialSlides.propTypes = {
  block: PropTypes.shape({
    slice_type: PropTypes.string.isRequired,
    slice_label: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        full_name: PropTypes.string.isRequired,
        handle: PropTypes.string,
        testimonial: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            type: PropTypes.string,
            spans: PropTypes.arrayOf(PropTypes.string),
          }),
        ),
        product_link: PropTypes.string,
        product_name: PropTypes.string,
        product_image: PropTypes.shape({
          alt: PropTypes.string,
          copyright: PropTypes.string,
          dimensions: PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number,
          }),
          url: PropTypes.string,
        }),
        headshot: PropTypes.shape({
          alt: PropTypes.string,
          copyright: PropTypes.string,
          dimensions: PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number,
          }),
          url: PropTypes.string,
        }),
      }),
    ).isRequired,
    primary: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default TestimonialSlides;
