import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import CarouselProgressPagination from "./CarouselProgressPagination";
function LargeReviewsCarousel({ block }) {
  const slidesShowNumber = 4;
  const [currentDot, setCurrentDot] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalSlidesShowing, setTotalSlidesShowing] = useState(1);
  const [screenWidth, setScreenWidth] = useState(null);
  const populateCarousel = () => {
    let postArray = [];
    if (block?.items?.length <= slidesShowNumber) {
      for (let i = 0; i <= slidesShowNumber; i++) {
        postArray.push(...block?.items);
      }
      postArray = postArray.slice(0, slidesShowNumber * 2);
    } else {
      postArray = block?.items;
    }
    return postArray;
  };
  const onBeforeChange = (currentSlide, nextSlide) => {
    console.log({
      screenWidth,
      nextSlide,
      pageCount,
      currentDot,
      totalSlidesShowing,
    });
    if (screenWidth > 428) {
      const lastPageSlide =
        pageCount * (totalSlidesShowing - 1) - (totalSlidesShowing - 1);
      if (nextSlide === 0) {
        setCurrentDot(1);
        return;
      }
      if (nextSlide === lastPageSlide) {
        setCurrentDot(pageCount);
        return;
      }
      if (nextSlide === 5) {
        setCurrentDot(2);
        return;
      }
    } else {
      setCurrentDot(nextSlide + 1);
    }
  };

  const reviewsData = screenWidth > 428 ? populateCarousel() : block?.items;

  const settings = {
    beforeChange: onBeforeChange,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesShowNumber,
    slidesToScroll: slidesShowNumber,
    variableWidth: true,
    nextArrow: (
      <CustomCarouselArrow
        src="/icons/arrow-left-prev.png"
        alt="arrow-left"
        rightPosition={40}
      />
    ),
    prevArrow: (
      <CustomCarouselArrow
        src="/icons/arrow-right-next.png"
        alt="arrow-right"
      />
    ),
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: slidesShowNumber,
          slidesToScroll: slidesShowNumber,
          infinite: 3,
          dots: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: 3,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: 3,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const width = window.innerWidth;
    const totalDots = Math.ceil(reviewsData?.length / slidesShowNumber);
    const slidesShowing = document.querySelectorAll(
      "#large-reviews-carousel .slick-active"
    )?.length;
    setPageCount(screenWidth > 428 ? totalDots : reviewsData?.length);
    setTotalSlidesShowing(slidesShowing);
    setScreenWidth(width);
  }, [reviewsData]);
  return (
    <>
      <div className="main">
        <div className="container">
          <div className="title">{block.primary.slice_label == null ? '3 countries. Over 150,000 happy customers' : block.primary.slice_label}</div>
          <div id="large-reviews-carousel" className="carousel">
            <Slider {...settings}>
              {reviewsData?.map((item, idx) => {
                const result = {
                  username: item.username,
                  rating: item.rating,
                  title: item.review_title,
                  message: item.review_content,
                  rig: item.rig,
                  imageURL: item.thumbnail?.url,
                  firstName: item.first_name,
                  instagramLink: item.instagram_link.url,
                };
                return <CarouselItem item={result} key={idx} />;
              })}
            </Slider>
            <CarouselProgressPagination
              currentDot={currentDot}
              totalDots={screenWidth > 428 ? pageCount : block?.items.length}
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        .carousel {
          margin-top: 32px;
        }
        .container {
          position: relative;
          max-width: 1440px;
          padding: 40px 48px;
          margin: 0 auto;
        }
        .title {
          font-family: "big_noodle_titling";
          font-size: 39px;
          font-weight: 400;
          line-height: 48px;
          letter-spacing: 0em;
          text-align: left;
          color: #e4e4e4;
        }
        @media (width<=991px) {
          .container {
            padding: 80px 16px;
          }
          .title {
            font-size: 26px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            width: 251px;
          }
        }
      `}</style>
    </>
  );
}

function CarouselItem({ item }) {
  const renderStars = () => {
    const arrayElements = [];
    let starsCount = 0;
    switch (item.rating) {
      case "1 Star":
        starsCount = 1;
        break;
      case "2 Stars":
        starsCount = 2;
        break;
      case "3 Stars":
        starsCount = 3;
        break;
      case "4 Stars":
        starsCount = 4;
        break;
      case "5 Stars":
        starsCount = 5;
        break;
    }
    for (var x = 1; x <= starsCount; x++) {
      arrayElements.push(<Star />);
    }
    return arrayElements;
  };

  return (
    <>
      <div className="card">
        <div className="image" />
        <div className="rate">
          <div>{renderStars()}</div>
          <div className="username">@{item.username}</div>
        </div>
        <div className="review_content">
          <a href={`${item.instagramLink}` || '#'} target="_blank">
            {item.title}
          </a>
          <div className="message">{item.message}</div>
        </div>
        <div className="usage">
          <div className="user">
            <span className="first_name">{item.firstName}</span> uses the
          </div>
          <div className="rig_name">{item.rig}</div>
        </div>
      </div>
      <style jsx>{`
        .usage {
          margin-top: 8px;
          display: flex;
          gap: 4px;
        }
        .rig_name {
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: #fd0300;
        }
        .first_name {
          text-transform: capitalize;
        }
        .user {
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: #e4e4e4;
        }
        .image {
          background: url('${item.imageURL}');
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          width: 304px;
          height: 304px;
          border-radius: 4px;
        }
        .rate {
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .username {
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: right;
          color: #e4e4e4;
        }
        .review_content {
          margin-top: 8px;
        }
        .title {
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: #e4e4e4;
          flex: none;
          order: 3;
          flex-grow: 0;
        }
        .message {
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: #949494;
          flex: none;
          order: 3;
          flex-grow: 0;
          margin-top: 8px;
        }
      `}</style>
    </>
  );
}

function Star() {
  return (
    <>
      <img draggable="false"
        src="/icons/rates/star-filled.svg"
        className="star_rating"
        alt="star-rating"
      />
      <style jsx>{`
        .star_rating {
          width: 16px;
          height: 16px;
        }
      `}</style>
    </>
  );
}

function CustomCarouselArrow({ src, alt, onClick, rightPosition }) {
  return (
    <>
      <button className="button" onClick={onClick}>
        <img draggable="false" src={src} alt={alt} />
      </button>
      <style jsx>{`
        .button {
          position: absolute;
          top: -57px;
          right: ${rightPosition || 0}px;
          background: none;
          border: none;
          padding: 0;
          margin: 0;
          line-height: 0;
        }
        @media (width<=991px) {
          .button {
            top: -94px;
          }
        }
      `}</style>
    </>
  );
}
export default LargeReviewsCarousel;