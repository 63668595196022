import React from "react";

function TrustersList({ block }) {
  const trusters = block?.items;
  return (
    <>
      <div className="main">
        <div className="container">
          <div className="title">{block?.primary?.slice_label}</div>
          <div className="trusters_list">
            {trusters?.map((item, idx) => {
              const companyItem = item.company_logo;
              return (
                <img draggable="false"
                  key={idx}
                  className="partner_logo"
                  src={companyItem.url}
                  alt={companyItem.alt}
                />
              );
            })}
          </div>
        </div>
      </div>
      <style jsx>{`
        .title {
          text-align: center;
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          color: #949494;
          text-transform: uppercase;
        }
        .trusters_list {
          margin-top: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 92px;
          flex-wrap: wrap;
          gap: 40px;
        }
        .main {
          background: #141414;
          padding: 64px 0;
        }
        .container {
          max-width: 1440px;
          margin: 0 auto;
          padding: 0 48px;
        }
        @media (width<=640px) {
          .container {
            padding: 0px 16px;
          }
          .partner_logo {
            width: 29%;
          }
          .trusters_list {
            margin-top: 32px;
            padding: 0 22px;
            display: flex;
            justify-content: center;
            gap: 20px;
          }
          .main {
            padding: 32px 0 48px 0;
          }
        }
      `}</style>
    </>
  );
}

export default TrustersList;