import PropTypes from 'prop-types';
import Link from 'next/link';
import breakPoints, { colors } from 'helpers/Styles';

const CategoryGrid = ({ block }) => {
  if (!block) return '';
  const { button_text: buttonText } = block.primary;
  const { items } = block;

  if (!items || !items.length) return '';

  return (
    <>
      <div className="category-grid">
        <div className="category-grid-container">
          {items.map((item) => (
            <div className="category-grid-card" key={item.title}>
              <Link href={item.link || '#'}>
                <a href={item.link || '#'}>
                  <div className="category-grid-card-title">{item.title}</div>
                  <div className="category-grid-card-image">
                    {item.image.url && (
                      <img draggable="false"
                        src={item.image.url}
                        alt={(item.image && item.image.alt) || 'Aftershock PC'}
                      />
                    )}
                  </div>

                  <div className="category-grid-card-link">
                    <span>{buttonText}</span>
                  </div>
                </a>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <style jsx>
        {`
          .category-grid {
            margin: 10px 0;
            background-color: ${colors.n500};
          }
          .category-grid-container {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
          }
          .category-grid-card {
            max-width: 280px;
            margin-bottom: 20px;
            border: none;
          }
          .category-grid-card:hover {
            opacity: 0.8;
          }
          .category-grid-card-title {
            font-size: 32px;
            color: ${colors.n0};
            font-weight: 400;
            text-transform: uppercase;
            text-align: center;
            padding: 20px 0;
          }
          .category-grid-card-image img {
            max-width: 280px;
          }
          .category-grid-card-link {
            margin-top: 10px;
            padding: 10px;
            text-align: center;
            text-transform: capitalize;
            color: ${colors.n0};
            background-color: ${colors.brandSecondary};
          }
          @media (max-width: ${breakPoints.small}) {
            .category-grid-container {
              flex-direction: column;
              align-items: center;
              margin-top: 20px;
            }
            .category-grid-card-title {
              font-size: 24px;
            }
            .category-grid-card {
              max-width: 240px;
            }
            .category-grid-card-image img {
              max-width: 240px;
            }
          }
        `}
      </style>
    </>
  );
};

CategoryGrid.propTypes = {
  block: PropTypes.shape({
    primary: PropTypes.shape({
      title: PropTypes.string,
      button_text: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
        }),
        title: PropTypes.string,
        link: PropTypes.string,
        link_type: PropTypes.string,
      })
    ),
  }),
};

CategoryGrid.defaultProps = {
  block: null,
};

export default CategoryGrid;
