import { useState, memo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import breakPoints from 'helpers/Styles';
import Link from 'next/link';
import arrow from 'public/icons/icon_arrow.svg';
import Image from 'next/image';
import CarouselBlock from './CarouselBlock';

const RangesCarousel = ({ block }) => {
  const {
    title1,
    subtitle,
    range_option_one,
    range_options_two,
    sub_range_option_one,
    sub_range_option_two,
    bottom_content,
    button_link,
    button_text,
  } = block.primary;

  const title = title1[0].text;
  const subTitle = subtitle[0].text;
  const rangeOptionOne = range_option_one[0].text;
  const rangeOptionTwo = range_options_two[0].text;
  const subRangeOptionOne = sub_range_option_one[0].text;
  const subRangeOptionTwo = sub_range_option_two[0].text;
  const buttonContent = bottom_content[0].text;
  const buttonLink = button_link[0].text;
  const buttonText = button_text[0].text;

  const [activeOptionOne, setActiveOptionOne] = useState(true);
  const [activeOptionTwo, setActiveOptionTwo] = useState(false);
  const [activeSubOptionOne, setActiveSubOptionOne] = useState(true);
  const [activeSubOptionTwo, setActiveSubOptionTwo] = useState(false);
  const [option, setOption] = useState('gaming');
  const [subOption, setSubOption] = useState('desktops');
  const optionOneSelected = () => {
    setActiveOptionOne(true);
    setActiveOptionTwo(false);
    setOption('gaming');
  };
  const optionTwoSelected = () => {
    setActiveOptionOne(false);
    setActiveOptionTwo(true);
    setOption('workstations');
  };
  const subOptionOneSelected = () => {
    setActiveSubOptionOne(true);
    setActiveSubOptionTwo(false);
    setSubOption('desktops');
  };
  const subOptionTwoSelected = () => {
    setActiveSubOptionOne(false);
    setActiveSubOptionTwo(true);
    setSubOption('laptops');
  };

  const selectedRange = `${option}/${subOption}`;

  return (
    <>
      <div className="ranges_carousel_slice">
        <div className="slice_title">
          <div className="title_container">
            <h4>{title}</h4>
            <h1>{subTitle}</h1>
          </div>
        </div>
        <div className="range_options">
          <div className="options_container">
            <div
              className={`option ${
                activeOptionOne === true ? 'selected_option' : ''
              }`}
              onClick={optionOneSelected}
            >
              {rangeOptionOne}
            </div>
            <div
              className={`option ${
                activeOptionTwo === true ? 'selected_option' : ''
              }`}
              onClick={optionTwoSelected}
            >
              {rangeOptionTwo}
            </div>
          </div>
        </div>
        <div className="sub_options_container">
          <div className="sub_range_options_container">
            <div
              className={`option ${
                activeSubOptionOne === true ? 'selected_sub_option' : ''
              }`}
              onClick={subOptionOneSelected}
            >
              {subRangeOptionOne}
            </div>
            <div
              className={`option ${
                activeSubOptionTwo === true ? 'selected_sub_option' : ''
              }`}
              onClick={subOptionTwoSelected}
            >
              {subRangeOptionTwo}
            </div>
          </div>
        </div>
        <CarouselBlock items={block.items} selectedRange={selectedRange} />

        <div className="find_you_setup">
          <div className="information_container">
            <h4>{buttonContent}</h4>
            <Link href={buttonLink || '#'}>
              <a href={buttonLink || '#'} className="button_container">
                <button type="button" className="two_column_button">
                  {buttonText}
                </button>
                <div className="arrow_right">
                  {arrow && <Image draggable={false} src={arrow} alt="find your setup" />}
                </div>
              </a>
            </Link>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .ranges_carousel_slice {
            min-height: 800px;
            background: black;
            color: white;
            padding-top: 30px;
            overflow: hidden;
          }
          .ranges_carousel_slice .slice_title p {
            width: 200px;
            margin: 0;
            text-algin: center !important;
          }
          .ranges_carousel_slice .slice_title h1 {
            color: #e4e4e4;
            font-size: 39px;
            letter-spacing: 0;
            line-height: 48px;
            text-align: center;
            font-family: 'big_noodle_titling';
          }
          .ranges_carousel_slice .slice_title h4 {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
          }
          .title_container {
            margin: 0 auto;
            text-algin: center;
          }
          .range_options {
            height: 80px;
            background: #242424;
            display: flex;
            justify-content: center;
            line-height: 80px;
            margin-bottom: 30px;
          }
          .sub_range_options_container {
            background: black;
            display: flex;
            justify-content: center;
            line-height: 40px;
            margin-bottom: 50px;
            text-align: center;
          }
          .sub_options_container .option {
            width: 110px;
            border-radius: 0 !important;
            height: 40px;
            background: black;
            border: 1px solid #949494;
            color: #e4e4e4;
            cursor: pointer;
          }
          .selected_sub_option {
            background: #950810 !important;
            border: none !important;
          }
          .sub_options_container .option button {
            width: 100%;
          }
          .options_container {
            display: flex;
            margin-left: 40px;
          }
          .options_container .option {
            margin-right: 40px;
            cursor: pointer;
          }
          .selected_option {
            border-bottom: 2px solid #fd0300;
          }
          .button_container {
            display: flex;
            width: 50%;
            margin: 0 auto;
            justify-content: center;
          }
          .two_column_button {
            height: 48px;
            min-width: 160px;
            color: white;
            background: #950810;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            border-radius: 3px 0 0 3px;
            border: none;
            outline: 0;
            padding: 12px 24px;
            text-transform: uppercase;
          }
          .arrow_right {
            width: 48px;
            height: 48px;
            background: #242424;
            display: flex;
            justify-content: center;
            cursor: pointer;
            border-radius: 0px 3px 3px 0px;
          }
          .button_container:hover > .two_column_button {
            background: #800006;
          }
          .button_container:hover > .arrow_right {
            background: #141414;
          }
          .arrow_right img {
            width: 25px;
          }
          .find_you_setup .information_container {
            width: 500px;
            margin: 0 auto;
          }
          .information_container h4 {
            text-align: center;
          }
          .find_more {
            width: 100%;
            display: flex;
            justify-content: center;
          }
          @media (max-width: ${breakPoints.small}) {
            .find_you_setup .information_container {
              width: 100%;
              padding: 0 15px;
            }
            .button_container {
              width: 100%;
            }

            .two_column_button {
              width: 100%;
            }
          }
        `}
      </style>
    </>
  );
};

export default memo(RangesCarousel);
