import { memo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import breakPoints from 'helpers/Styles';

const ColumnsCarouselMobile = ({ items }) => (
  <>
    <div className="columns_carousel_slice_mobile">
      <Carousel
        showStatus={false}
        showThumbs={false}
        useKeyboardArrows
        infiniteLoop
        autoPlay
        interval={5000}
        showIndicators={false}
      >
        {items &&
          items.map((item, index) => (
            <div className="columns_carousel" key={index}>
              <div className="columns_carousel_container">
                <div className="column_block">
                  <div className="icon">“</div>
                  <p className="column_contents">{item.column}</p>
                  <div className="image_block">
                    {item.image && (
                      <img draggable="false" src={item.image} alt="column_image" className="column_image" />
                    )}
                    <div className="column_information">
                      <p className="name">{item.name}</p>
                      <p className="content">{item.content}</p>
                      <p className="other_content">{item.other_content}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Carousel>
    </div>
    <style jsx>
      {`
        .columns_carousel_slice_mobile {
          min-height: 600px;
          padding: 60px 30px;
          background: black;
          z-index: important;
          overflow: hidden;
          display: none;
        }
        .columns_carousel {
          display: flex;
          width: 100%;
          justify-content: center;
        }
        .columns_carousel_container {
          display: flex;
          width: 100%;
          justify-content: center;
          padding-left: 2%;
        }
        .column_block {
          color: white;
          min-height: 300px;
          background: #141414;
          width: 100%;
          margin-right: 30px;
          text-algin: center;
          padding: 10px 20px;
          position: relative;
        }
        .column_block .icon {
          height: 90px;
        }
        .image_block {
          position: absolute;
          width: 90%;
          height: 100px;
          position: absolute;
          left: 50%;
          bottom: -10%;
        }
        .column_image {
          height: 80px !important;
          width: 80px !important;
        }
        .column_information p {
          color: #949494;
          margin: 0;
        }
        .columns_carousel_slice .name {
          color: #949494;
          font-family: 'Roboto-Medium';
          font-size: 13px;
          letter-spacing: 0;
          line-height: 20px;
        }
        .columns_carousel_slice .content {
          color: #e4e4e4;
          font-family: 'Roboto-Medium';
          font-size: 13px;
          letter-spacing: 0;
          line-height: 20px;
        }
        .columns_carousel_slice .other_content {
          color: #949494;
          font-family: 'Roboto-Medium';
          font-size: 9px;
          letter-spacing: 0;
          line-height: 16px;
        }
        .icon {
          height: 87px;
          color: #fd0300;
          font-family: 'big_noodle_titling';
          font-size: 80px;
          letter-spacing: 0;
          margin-top: 10px;
        }
        .column_contents {
          color: #e4e4e4;
          font-family: 'Roboto-Medium';
          font-size: 13px;
          letter-spacing: 0;
          line-height: 20px;
          text-align: center;
        }
        @media (max-width: ${breakPoints.medium}) {
          .columns_carousel_slice_mobile {
            display: block;
          }
          .column_block {
            margin-right: 0 !important;
            padding: 10px 30px;
          }
          .image_block {
            width: 100%;
            left: 0% !important;
            bottom: -20%;
          }
        }
        @media (max-width: ${breakPoints.small}) {
          .image_block {
            width: 100%;
            left: 0% !important;
          }
        }
        @media (max-width: 450px) {
          .image_block {
            width: 100%;
            bottom: -15%;
          }
          .column_block {
            margin-right: 0 !important;
            padding: 10px 50px;
            min-height: 400px;
          }
        }
      `}
    </style>
  </>
);

export default memo(ColumnsCarouselMobile);
