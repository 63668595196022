import { RichText } from 'prismic-reactjs';
import PropTypes from 'prop-types';
import breakPoints from 'helpers/Styles';

const FreeText = ({ block }) => {
  if (!block) return '';
  const { title, text } = block.primary;

  return (
    <>
      <div className="freeText">
        <h1 className="freeTextTitle">{title}</h1>
        {text && <div className="freeTextContent">{RichText.render(text)}</div>}
      </div>
      <style jsx>
        {`
          .freeText {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0;
          }
          .freeTextTitle {
            font-weight: 500;
            text-transform: uppercase;
          }
          .freeTextContent {
            max-width: 70%;
            text-align: left;
            line-height: 1.5;
          }
          @media (max-width: ${breakPoints.small}) {
            .freeTextContent {
              max-width: 90%;
              line-height: 1.2;
            }
        `}
      </style>
    </>
  );
};

FreeText.propTypes = {
  block: PropTypes.shape({
    primary: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.arrayOf(String),
    }),
  }),
};

FreeText.defaultProps = {
  block: null,
};

export default FreeText;
