import PropTypes from 'prop-types';
import breakPoints from 'helpers/Styles';

const ImageOnly = ({ block }) => {
  if (!block) return '';
  const { primary } = block;
  return (
    <>
      <div className="image-only">
        {primary.image.url && (
          <img draggable="false"
            src={primary.image.url}
            alt={primary.image.alt || 'Aftershock PC'}
          />
        )}
      </div>
      <style jsx>
        {`
          img {
            width: 100%;
          }
          @media (max-width: ${breakPoints.small}) {
            margin-bottom: 0;
          }
        `}
      </style>
    </>
  );
};

ImageOnly.propTypes = {
  block: PropTypes.shape({
    primary: PropTypes.shape({
      image: PropTypes.shape({ url: PropTypes.string, alt: PropTypes.string }),
    }),
  }),
};

ImageOnly.defaultProps = {
  block: null,
};

export default ImageOnly;
