import Image from 'next/image';
import breakPoints, { colors } from 'helpers/Styles';
import Link from 'next/link';

const LogosBanner = ({ block }) => {
  if (!block) return '';
  const { items } = block;

  if (!items || !items.length) return '';
  return (
    <>
      <div className="logos_banner">
        <div className="logos_banner_container">
          {items.map((item, index) => (
            <>
              {item?.link?.url ? (
                <Link href={item?.link?.url || '#'}>
                  <a key={index} target="_blank">
                    {item.logo.url && (
                      <img draggable="false" src={item.logo.url} alt="logo" layout="fixed" />
                    )}
                  </a>
                </Link>
              ) : (
                <a key={index} target="_blank">
                  {item.logo.url && (
                    <img draggable="false" src={item.logo.url} alt="logo" layout="fixed" />
                  )}
                </a>
              )}
            </>
          ))}
        </div>
      </div>
      <style jsx>
        {`
          .logos_banner {
            background: #242424;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 0px 15px;
            // padding-bottom: 20px;
          }
          .logos_banner_container {
            width: 100%;

            max-width: 950px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
          }

          .logos_banner_container div {
            padding-top: 20px;
          }

          .logos_banner_container img {
            max-width: 104px;
          }
          @media (max-width: ${breakPoints.small}) {
          }
        `}
      </style>
    </>
  );
};

export default LogosBanner;
